import React, { useEffect, useState } from 'react';
import Header from "../components/header";
import Footer from "../components/footer";
import Scroll from '../components/scroll';
import "@fontsource/dm-sans/700.css";
import "@fontsource/dm-sans/400.css";
import { Helmet } from "react-helmet";
import i18next from "i18next";


const Timeline = () => {
    const [isLocalStorageAvailable, setLocalStorageAvailable] = useState(false);
  const [language, setLanguage] = useState('en');  // default language

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      setLocalStorageAvailable(true);
      const lang = localStorage.getItem('lang') || 'en';  // default to 'en' if not set
      setLanguage(lang);
    }
  }, []);
    return (
        <main>
            <Helmet htmlAttributes={{ lang: language }}>
                <meta charSet="utf-8" />
                <meta name="description" content="Rejoice is a dating app designed to help you find your soulmate, new friends or someone to hookup with based on your compatibility" />
                <meta name="keywords" content="Rejoice, dating, app, new, big5, concept, young, ipq, meet, people, girls" />
                <meta name="author" content="Rejoice" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>{i18next.t("timeline.header.title")}</title>
            </Helmet>
            <Scroll showBelow={250} />
            <Header />
            <div className="md:relative py-16 lg:py-16 bg-white">
                <div className="w-10/12 md:container mx-auto ">
                    <ol className="relative border-l  border-gray-200 ">
                    <li className="mb-10 ml-6">
                            <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-blue-200 rounded-full ring-8 ring-white  ">
                                <svg className="w-3 h-3 text-blue-600 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
                            </span>
                            <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900 ">Rejoice - Beta-v1.12.0 <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">Dernière</span></h3>
                            <time className="block mb-2 text-sm font-normal leading-none text-gray-400 ">13/06/2022</time>
                            <h4 className="text-xl mb-6 mt-6 font-normal leading-3 text-gray-700">Nouveautés</h4>
                            <ul className="list-disc pl-6">
                                <li className="mb-2">Limite de 20 swipes à droite par jour, afin de ne pas surcharger les serveurs pendant la beta</li>
                                <li className="mb-2">Vous pouvez désormais revoir le profil de vos matchs en cliquant sur leur photo de profil depuis la fenêtre de discussion</li>
                                <li className="mb-2">Vous pouvez désormais "unmatch" quelqu'un depuis la fenêtre de match, attention cette personne n'apparaitra plus dans la stack de profils</li>
                            </ul>
                            <h4 className="text-xl mb-6 mt-6 font-normal leading-3 text-gray-700">Mises à jour</h4>
                            <ul className="list-disc pl-6">
                                <li className="mb-2">Refonte de la page d'accueil</li>
                                <li className="mb-2">Ajout de (?) et (I) informatifs sur les pages de résultats</li>
                                <li className="mb-2">Le bouton vert à côté des noms sur les cartes ne sera affiché que si l'utilisateur s'est connecté il y a moins de 24h</li>
                            </ul>
                            <p className="mb-4 text-base font-normal hidden text-gray-500 ">Get access to over 20+ pages including a dashboard layout, charts, kanban board, calendar, and pre-order E-commerce & Marketing pages.</p>
                        </li>
                        <li className="mb-10 ml-6">
                            <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-blue-200 rounded-full ring-8 ring-white  ">
                                <svg className="w-3 h-3 text-blue-600 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
                            </span>
                            <h3 className="mb-1 text-lg font-semibold text-gray-900 ">Rejoice - beta-v1.13.0</h3>
                            <time className="block mb-2 text-sm font-normal leading-none text-gray-400 ">Planifiée pour le 20 juin 2022</time>
                            <h4 className="text-xl mb-6 mt-6 font-normal leading-3 text-gray-700">Nouveautés</h4>
                            <ul className="list-disc pl-6">
                                <li className="mb-2">Notifications (messages et matchs)</li>
                                <li className="mb-2">Mise en surbrillance des valeurs et buts de vies en commun</li>
                                <li className="mb-2">Ajout des facteurs IPQ sur les cartes</li>
                                <li className="mb-2">Possibilité de préciser son sexe et/ou sa sexualité quand vous sélectionnez "Autre" (LGBTQ+ friendly)</li>
                            </ul>
                            <h4 className="text-xl mb-6 mt-6 font-normal leading-3 text-gray-700">Technique</h4>
                            <ul className="list-disc pl-6">
                                <li className="mb-2">Correction du bug Iphone lors des swipes</li>
                                <li className="mb-2">Mise à jour de l'algorithme d'exploration</li>
                                <li className="mb-2">Mise à jour de l'algorithme de compatibilité</li>
                            </ul>
                        </li>
                        <li className="mb-10 ml-6">
                            <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-blue-200 rounded-full ring-8 ring-white  ">
                                <svg className="w-3 h-3 text-blue-600 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
                            </span>
                            <h3 className="mb-1 text-lg font-semibold text-gray-900 ">Rejoice - beta-v1.14.0</h3>
                            <time className="block mb-2 text-sm font-normal leading-none text-gray-400 ">Planifiée pour le 27 juin 2022</time>
                            <h4 className="text-xl mb-6 mt-6 font-normal leading-3 text-gray-700">Nouveautés</h4>
                            <ul className="list-disc pl-6">
                                <li className="mb-2">Localisation</li>
                                <li className="mb-2">Système d'abonnement premium</li>
                                <li className="mb-2">Détail des compatibilités (Premium)</li>
                                <li className="mb-2">Possibilité de signaler un utilisateur</li>
                            </ul>
                            <h4 className="text-xl mb-6 mt-6 font-normal leading-3 text-gray-700">Technique</h4>
                            <ul className="list-disc pl-6">
                                <li className="mb-2">Activation de tous les filtres pour les membres premium</li>
                                <li className="mb-2">Correction de bugs</li>
                            </ul>
                            <h4 className="text-xl mb-6 mt-6 font-normal leading-3 text-gray-700">Mises à jour</h4>
                            <ul className="list-disc pl-6">
                                <li className="mb-2">Refonte de plusieurs pages du formulaire d'inscription</li>
                            </ul>
                        </li>
                    </ol>
                </div>
            </div>
            <Footer />
        </main>
    )
}
export default Timeline